.calendar-aside {
  display: block;

  .list {
    position: relative;
    padding: 10px 8px;
    // compass-mixins 제거
    //@include display-flex;
    //@include align-items(center);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;

    @include border-radius(5px);
    // compass-mixins 제거
    //@include transition-duration($action-transition-duration);
    //@include transition-property(background);
    -webkit-transition-duration: $action-transition-duration;
    -moz-transition-duration: $action-transition-duration;
    -o-transition-duration: $action-transition-duration;
    transition-duration: $action-transition-duration;
    -webkit-transition-property: background;
    -moz-transition-property: background;
    -o-transition-property: background;
    transition-property: background;

    .user-text {
      margin-bottom: 0;
      margin-left: 20px;
      font-size: $default-font-size;
    }

    .count {
      width: 20px;
      height: 20px;
      position: relative;
      @include border-radius(50px);
      // compass-mixins 제거
      //@include transition-duration($action-transition-duration);
      //@include transition-timing-function($action-transition-timing-function);
      //@include transition-property(opacity);
      //@include display-flex;
      //@include align-items(center);
      //@include justify-content(center);
      -webkit-transition-duration: $action-transition-duration;
      -moz-transition-duration: $action-transition-duration;
      -o-transition-duration: $action-transition-duration;
      transition-duration: $action-transition-duration;
      -webkit-transition-timing-function: $action-transition-timing-function;
      -moz-transition-timing-function: $action-transition-timing-function;
      -o-transition-timing-function: $action-transition-timing-function;
      transition-timing-function: $action-transition-timing-function;
      -webkit-transition-property: opacity;
      -moz-transition-property: opacity;
      -o-transition-property: opacity;
      transition-property: opacity;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: center;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        display: block;
        height: 10px;
        width: 10px;
        margin: auto;
        @include border-radius(50px);
        @include transform(translateY(-50%));
        // compass-mixins 제거
        //@include transition-duration($action-transition-duration);
        //@include transition-timing-function($action-transition-timing-function);
        -webkit-transition-duration: $action-transition-duration;
        -moz-transition-duration: $action-transition-duration;
        -o-transition-duration: $action-transition-duration;
        transition-duration: $action-transition-duration;
        -webkit-transition-timing-function: $action-transition-timing-function;
        -moz-transition-timing-function: $action-transition-timing-function;
        -o-transition-timing-function: $action-transition-timing-function;
        transition-timing-function: $action-transition-timing-function;
      }

      &.online {
        &:before {
          background: theme-color(success);
        }
      }

      &.offline {
        &:before {
          background: theme-color(danger);
        }
      }

      .close {
        font-size: 15px;
        margin: auto;
        opacity: 0;
        color: theme-color(danger);
        // compass-mixins 제거
        //@include transition-duration($action-transition-duration);
        //@include transition-timing-function($action-transition-timing-function);
        -webkit-transition-duration: $action-transition-duration;
        -moz-transition-duration: $action-transition-duration;
        -o-transition-duration: $action-transition-duration;
        transition-duration: $action-transition-duration;
        -webkit-transition-timing-function: $action-transition-timing-function;
        -moz-transition-timing-function: $action-transition-timing-function;
        -o-transition-timing-function: $action-transition-timing-function;
        transition-timing-function: $action-transition-timing-function;
      }
    }

    &:hover {
      background: theme-color(light);

      .count {
        &:before {
          z-index: 0;
          height: 20px;
          width: 20px;
          background: theme-color(light);
        }

        .close {
          z-index: 2;
          opacity: 1;
        }
      }
    }
  }
}

.card-statistics {
  .card-statistics-item {
    padding: 2rem 2.25rem 2rem 1.75rem;
  }
}

.card-update {
  .update-item {
    &:not(:last-child) {
      border-bottom: 1px solid rgba($white, .2);
    }
  }
}