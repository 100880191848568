/* Flot chart */
.float-chart-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 100%;
}

.float-chart {
  width: 100%;
  position: relative;
  max-width: none;
  height: 400px;
}
