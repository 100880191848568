/* Lists */
dl,
ol,
ul {
  padding-left: 1rem;
  font-size: $default-font-size;

  li {
    line-height: 1.8;
  }
}

.list-arrow,
.list-star,
.list-ticked {
  list-style: none;
  padding: 0;

  li {
    padding-left: 1.5rem;

    &:before {
      font-family: 'simple-line-icons';
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      margin-left: -1.5rem;
      width: 1.5rem;
      margin-right: 0.5rem;
    }
  }
}

.list-ticked {
  li {
    &:before {
      content: "\e080";
      color: theme-color(primary);
    }
  }
}

.list-arrow {
  li {
    &:before {
      content: "\e606";
      color: theme-color(success);
    }
  }
}

.list-star {
  li {
    &:before {
      content: "\e09b";
      color: theme-color(warning);
    }
  }
}

.bullet-line-list {
  padding-left: 30px;
  position: relative;
  list-style-type: none;
  .rtl & {
    padding-left: unset;
    padding-right: 30px;
  }

  li {
    position: relative;

    &:before {
      width: 15px;
      height: 15px;
      left: -30px;
      top: 15px;
      border: 4px solid theme-color(primary);
      margin-right: 15px;
      z-index: 2;
      background: color(white);

      .rtl & {
        left: unset;
        right: -45px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      border-radius: 100%;
    }
  }

  &:after {
    content: "";
    border: 1px solid $border-color;
    position: absolute;
    top: 30px;
    bottom: 0;
    left: 7px;
    .rtl & {
      left: unset;
      right: 7px;
    }
  }
}