/*Fonts*/

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/openSans/OpenSans-Light.woff2') format('woff2'),
        url('../fonts/openSans/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/openSans/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/openSans/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/openSans/OpenSans-SemiBold.woff2') format('woff2'),
        url('../fonts/openSans/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/openSans/OpenSans-ExtraBold.woff2') format('woff2'),
        url('../fonts/openSans/OpenSans-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/openSans/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/openSans/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

