/* Timeline */
.vertical-timeline {
	list-style: none;
	padding: 0;
	position: relative;

	&:before {
		top: 0;
		bottom: 0;
		position: absolute;
		content: " ";
		width: 3px;
		background-color: theme-color(secondary);
		left: 50%;
		margin-left: -1.5px;
	}

	.timeline-wrapper {
		display: block;
		margin-bottom: 20px;
		position: relative;
		width: 100%;
		padding-right: 90px;

		&:before {
			content: " ";
			display: table;
		}

		&:after {
			content: " ";
			display: table;
			clear: both;
		}

		.timeline-panel {
			border-radius: 2px;
			padding: 20px;
			position: relative;
			background: $white;
			@include border-radius(6px);
			box-shadow: 1px 2px 35px 0 rgba(1, 1, 1, 0.1);
			width: 35%;
			margin-left: 15%;

			&:before {
				position: absolute;
				top: 0;
				width: 100%;
				height: 2px;
				content: "";
				left: 0;
				right: 0;
			}

			&:after {
				position: absolute;
				top: 27px;
				right: -14px;
				display: inline-block;
				border-top: 14px solid transparent;
				border-left: 14px solid $white;
				border-right: 0 solid $white;
				border-bottom: 14px solid transparent;
				content: " ";
			}

			.timeline-title {
				margin-top: 0;
				color: theme-color(dark);
				text-transform: uppercase;
			}

			.timeline-body {
				p {
					margin-bottom: 0;

					&+p {
						margin-top: 5px;
					}
				}

				ul {
					margin-bottom: 0;
				}
			}

			.timeline-footer {
				span {
					font-size: 0.6875rem;
				}

				i {
					font-size: 1.5rem;
				}
			}
		}

		.timeline-badge {
			width: 14px;
			height: 14px;
			position: absolute;
			top: 16px;
			left: calc(50% - 7px);
			z-index: 100;
			border-top-right-radius: 50%;
			border-top-left-radius: 50%;
			border-bottom-right-radius: 50%;
			border-bottom-left-radius: 50%;
			border: 2px solid $white;

			i {
				color: $white;
			}
		}

		&.timeline-inverted {
			padding-right: 0;
			padding-left: 90px;

			.timeline-panel {
				margin-left: auto;
				margin-right: 15%;

				&:after {
					border-left-width: 0;
					border-right-width: 14px;
					left: -14px;
					right: auto;
				}
			}
		}
	}
}
@media (max-width: 767px) {
	.vertical-timeline {
		.timeline-wrapper {
			padding-right: 150px;

			&.timeline-inverted {
				padding-left: 150px;
			}

			.timeline-panel {
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}
@each $color, $value in $theme-colors {
	.timeline-wrapper-#{$color} {
		.timeline-panel {
			&:before {
				background: theme-color($color);
			}
		}

		.timeline-badge {
			background: theme-color($color);
		}
	}
}