/* Icons */
.icons-list {
  border-left: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  > div {
    background: $white;
    border-top: 1px solid $border-color;
    border-right: 1px solid $border-color;
    // compass-mixins 제거
    //@include display-flex;
    //@include align-items(center);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 15px;
    font-family: $type-1;
    font-size: $default-font-size;

    i {
      display: inline-block;
      font-size: 20px;
      width: 40px;
      text-align: left;
      color: theme-color(primary);
    }
  }
}

.icon-in-bg {
  width: 55px;
  height: 55px;
  text-align: center;
  // compass-mixins 제거
  //@include display-flex();
  //@include align-items(center);
  //@include justify-content(center);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}