
////////// COINNET SCSS //////////

// 김치 프리미엄
.table_premium {
  tbody > tr > td {
    padding: 0.325rem;
    &:first-child {
      text-align: center;
      font-weight: bold;
    }
  }
  tbody > tr > td > div {
    height:15px;
    padding-left: 0.325rem;
    color: #fff;
    &.plus {
      background: #439aff;
    }

    &.minus {
      background: #ff5e5e;
    }

    &.out1 {
      background: #f5a623;
    }

    &.out2 {
      background: #439aff;
    }

    &.out3 {
      background: #ff5e5e;
    }

    &.out4 {
      background: #4729b7;
    }
  }
}

// 버그 있잖아.
.modal-open {
  overflow: hidden;
  position:fixed;
  width: 100%;
}

// 버그 수정..
.vertical-timeline {
  .timeline-wrapper {
    .timeline-badge {
      z-index: 1;
    }
  }
}

.card-bid {
  padding: 1.05rem!important;
}